<template>
  <div>
      <v-dialog
          v-model="importCSVDialog"
          width="600"
      >
          <v-card>
              <v-card-title>
                  <span class="text-h5">Import file to parsing for the {{importCSVCompetitor.domain}} domain</span>
              </v-card-title>

              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col cols="12">
                              Upload here the CSV file you got from ahrefs.
                              <div class="d-flex ms-auto">
                                  <v-file-input
                                      accept=".csv"
                                      label="Select CSV file"
                                      small-chips
                                      show-size
                                      clearable
                                      v-model="importFile"
                                  ></v-file-input>
                              </div>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="importCSVFile()"
                  >
                      Import
                  </v-btn>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="hideImportCSVDialog()"
                  >
                      Cancel
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
    <v-container fluid class="py-6">
        <div class="d-sm-flex justify-between">
            <div class="d-flex ms-auto">
                <v-btn
                    elevation="0"
                    height="43"
                    class="
                      font-weight-bold
                      text-uppercase
                      btn-default btn-outline-default
                      shadow-none
                      py-2
                      px-6
                      me-2
                    "
                    color="transparent"
                    small
                    @click="goToCreationPage()"
                >
                    Create
                </v-btn>
            </div>
        </div>

      <v-row class="my-5">
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
              <v-data-table
                  :headers="headers"
                  :items="competitors"
                  :items-per-page="1000"
                  class="table border-radius-xl"
                  hide-default-footer
              >
                  <template v-slot:item.last_date_analyse="{ item }">
                      {{ prepareDateOfAnalyse(item) }}
                  </template>
                  <template v-slot:item.next_date_analyse="{ item }">
                      {{ prepareNextDateOfAnalyse(item) }}
                  </template>
                  <template v-slot:item.responsible_user="{ item }">
                      {{ prepareResponsibleUser(item) }}
                  </template>
                  <template v-slot:item.status="{ item }">
                      <div class="">
                          <v-btn
                              elevation="0"
                              small
                              :ripple="false"
                              height="21"
                              outlined
                              class="
                                  border-radius-md
                                  font-weight-bolder
                                  px-3
                                  py-3
                                  badge-font-size
                                  ms-auto
                                "
                              :color="evaluateStatusColor(item.status)"
                          >
                              &nbsp;{{ item.status }}
                          </v-btn>
                      </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                      <v-btn
                          @click="showImportCSVDialog(item)"
                          icon
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="btn-ls me-2 my-2 rounded-sm"
                          color="#67748e"
                      >
                          <v-icon size="14" class="material-icons-round"
                          >
                              fas fa-upload
                          </v-icon>
                      </v-btn>
                      <v-btn
                          @click="editItem(item)"
                          icon
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="btn-ls me-2 my-2 rounded-sm"
                          color="#67748e"
                      >
                          <v-icon size="14" class="material-icons-round"
                          >
                              fas fa-edit
                          </v-icon>
                      </v-btn>
                      <v-btn
                          @click="deleteItem(item)"
                          icon
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="btn-ls me-2 my-2 rounded-sm"
                          color="#67748e"
                      >
                          <v-icon size="14" class="material-icons-round">
                              fas fa-trash-alt
                          </v-icon>
                      </v-btn>
                  </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axiosIns from '../../../plugins/axios';

export default {
  name: "CompetitorList",
  components: {},
  data: () => {
      return {
          importCSVDialog: false,
          importCSVCompetitor: {},
          importFile: null,
          competitors: [],
          headers: [
              // {
              //     text: "Id",
              //     align: "start",
              //     sortable: false,
              //     value: "id",
              //     class: "text-secondary font-weight-bolder opacity-7 border-bottom",
              // },
              {
                  text: "Domain",
                  value: "domain",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Responsible User",
                  value: "responsible_user",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Last Date of Analysis",
                  value: "last_date_analyse",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Next Date Analyse",
                  value: "next_date_analyse",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Status",
                  value: "status",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Donors Count",
                  value: "donors_count",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Records Last Time",
                  value: "last_analyse_total_donors",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Failed Last Time",
                  value: "last_analyse_failed_donors",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Updated Last Time",
                  value: "last_analyse_updated_donors",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Added Last Time",
                  value: "last_analyse_new_donors",
                  class: "text-secondary font-weight-bolder opacity-7",
              },
              {
                  text: "Actions",
                  value: "actions",
                  sortable: false,
                  class: "text-secondary font-weight-bolder opacity-7",
              },
          ]
      };
  },
  computed: {
      users() {
          return this.$store.getters['other/linkbuilders'];
      },
  },
  beforeMount () {
      this.loadCompetitors();
  },
  methods: {
      async importCSVFile() {
          const formData = new FormData();
          formData.append('file', this.importFile);

          this.$store.commit('app/SHOW_LOADING');
          const response = await axiosIns.post(`/api/competitors/${this.importCSVCompetitor.id}/parse-csv`, formData);

          this.hideImportCSVDialog();
          if (response && response.status === 200) {
              this.$store.commit('app/SNACKBAR', {
                  show: true,
                  body: 'File imported successfully',
                  type: 'success',
              });
          } else {
              this.$store.commit('app/SNACKBAR', {
                  show: true,
                  body: 'Error happened, ensure that file is correct',
                  type: 'error',
              });
          }
          this.$store.commit('app/HIDE_LOADING');
      },
      showImportCSVDialog(item) {
          this.importCSVCompetitor = item;
          this.importCSVDialog = true;
      },
      hideImportCSVDialog() {
          this.importCSVCompetitor = {};
          this.importCSVDialog = false;
      },
      prepareResponsibleUser(item) {
            if ('responsible_user_id' in item && item.responsible_user_id !== null) {
                let user = this.users.find(x => x.id === item.responsible_user_id);
                if (user) {
                    return user.email;
                }
            }
          return '';
      },
      prepareDateOfAnalyse(item) {
          if ('last_date_analyse' in item && item.last_date_analyse !== null && item.last_date_analyse.length > 5) {
              let date = item.last_date_analyse.split(' ')[0];
              let dateItems = date.split('-');
              return dateItems[1] + '/' + dateItems[2] + '/' + dateItems[0]; // mm/dd/yyyy
          }
          return '--';
      },
      prepareNextDateOfAnalyse(item) {
          if ('next_date_analyse' in item && item.next_date_analyse !== null && item.next_date_analyse.length > 5) {
              let date = item.next_date_analyse.split(' ')[0];
              let dateItems = date.split('-');
              return dateItems[1] + '/' + dateItems[2] + '/' + dateItems[0]; // mm/dd/yyyy
          }
          return '--';
      },
      evaluateStatusColor(status) {
          if (status === 'done') {
              return 'green';
          } else if (status === 'error') {
              return 'red';
          } else if (status === 'pending') {
              return 'blue';
          } else {
              return 'yellow';
          }
      },
      async loadCompetitors() {
          const response = await axiosIns.get('/api/competitor-domains');
          if (response && response.status === 200) {
              this.competitors = response.data.competitorDomains.data;
          }
      },
      goToCreationPage() {
          this.$router.push('competitors/create')
      },
      editItem(item) {
          this.$router.push('competitors/'+item.id);
      },
      async deleteItem(item) {
          if (window.confirm("Do you really want to delete this domain?")) {
              axiosIns.delete('/api/competitors/'+item.id)
                  .then((response) => {
                      console.log(response);
                      this.$router.go(this.$router.currentRoute);
                  })
                  .catch((error) => {
                      this.$store.commit('app/SNACKBAR', {
                          show: true,
                          body: error.response.data.message,
                          type: 'error',
                      });
                  });
          }
      }
  }
};
</script>
